import { EventAggregator } from 'aurelia-event-aggregator'
import { Api } from 'common/server';
import { MemberConfig } from 'services/member-config';
import { c } from 'common/common';

export class Calendar {
    static inject = [EventAggregator, Api, MemberConfig];
    _ea;
    _api;
    _memberConfig;

    constructor(ea, api, memberConfig) {
        this._ea = ea;
        this._api = api;
        this._memberConfig = memberConfig;
    }

    async events(view, timePeriod, notificationTypes, start, end) {
        const qs = [];
        qs.push(`view=${encodeURIComponent(view)}`);
        if (notificationTypes) qs.push(`include=${encodeURIComponent(notificationTypes)}`);
        if (start) qs.push(`start=${encodeURIComponent(start)}`);
        if (end) qs.push(`end=${encodeURIComponent(end)}`);
        return await this._api.get(`calendar/notifications/${encodeURIComponent(timePeriod)}?${qs.join('&')}`);
    }

    async yourCalendarOnline(memberId) {
        let url = 'your-calendar-online/status';
        if (memberId) url += `?memberId=${encodeURIComponent(memberId)}`;
        return await this._api.get(url);
    }

    async saveYourCalendarOnline(slug, settings, memberId) {
        let url = 'your-calendar-online/settings';
        if (memberId) url += `?memberId=${encodeURIComponent(memberId)}`;
        return await this._api.post(url, { slug, settings });
    }

    async schedulingPages(memberId) {
        let url = 'your-calendar-online/scheduling-pages';
        if (memberId) url += `?memberId=${encodeURIComponent(memberId)}`;
        return await this._api.get(url);
    }

    async saveSchedulingPages(pages, memberId) {
        let url = 'your-calendar-online/scheduling-pages';
        if (memberId) url += `?memberId=${encodeURIComponent(memberId)}`;
        return await this._api.post(url, pages);
    }

    async deleteYourCalendarOnline(memberId) {
        let url = 'your-calendar-online';
        if (memberId) url += `?memberId=${encodeURIComponent(memberId)}`;
        const response = await this._api.delete(url);
        await this._memberConfig.reset();
        return response;
    }

    downlineUsesSchedulingPages() {
        return this._memberConfig.value('your-calendar-downline-use-scheduler-pages') ? true : false;
    }

    async setDownlineUseSchedulingPages(useSchedulingPages, forMemberId) {
        let url = `your-calendar-online/set-downline-use-scheduling-pages?downlineUse=${useSchedulingPages ? 'true' : 'false'}`;
        if (forMemberId) url += `&memberId=${encodeURIComponent(forMemberId)}`;
        const response = await this._api.post(url);
        await this._memberConfig.reset();
        return response;
    }

    openSchedulerPage(slug, name, email, phone, address, dob, zIndex) {
        const params = [];
        if (name) params.push(`name=${encodeURIComponent(name)}`);
        if (email) params.push(`email=${encodeURIComponent(email)}`);
        if (phone) params.push(`phone=${encodeURIComponent(phone)}`);
        if (address) params.push(`address=${encodeURIComponent(address)}`);
        if (dob) params.push(`date%20of%20birth=${encodeURIComponent(dob)}`);
        let url = `https://schedule.nylas.com/${encodeURIComponent(slug)}`;
        if (params.length) url += `?${params.join('&')}`;
        this._ea.publish(c.EventKeys.site.openLightbox, { embedSrc: url, fullHeight: true, zIndex });
    }
}
