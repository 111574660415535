import { Api } from 'common/server';
import { HttpClient } from 'aurelia-http-client';
import * as topojson from 'topojson-client';
import environment from './../../config/environment.json';

export class Geography {
    static inject = [Api];
    _api;

    constructor(api) {
        this._api = api;
    }

    async usStates() {
        return await this._api.get('geography/us-states');
    }

    async usCounties(usState) {
        return await this._api.get(`geography/us-counties/${encodeURIComponent(usState)}`);
    }

    async zips(usCounties) {
        return await this._api.get(`geography/zips/${encodeURIComponent(usCounties.join(','))}`);
    }

    async countiesByFips(usCounties) {
        return await this._api.get(`geography/us-counties/by-fips/${encodeURIComponent(usCounties.join(','))}`);
    }

    geojson = {
        usCounties: async() => {
            const httpClient = new HttpClient();
            const data = await Promise.all([
                this.topojsonToGeojson(`${environment.url}/maps/us-counties.json`, 'counties'),
                httpClient.get(`${environment.url}/maps/ct-counties.geojson`),
                httpClient.get(`${environment.url}/maps/va-counties.geojson`),
            ]);
            const vaCountyFeatures = JSON.parse(data[2].response).features;
            vaCountyFeatures.forEach(c => {
                c.id = c.properties.GEOID;
                c.properties.name = c.properties.NAME;
                delete c.properties.NAME;
            });
            const vaCountyIds = vaCountyFeatures.map(x => x.id);
            const usCountyFeatures = data[0].features.filter(x => !vaCountyIds.includes(x.id));
            return { type: 'FeatureCollection', features: [...usCountyFeatures, ...JSON.parse(data[1].response).features, ...vaCountyFeatures]};
        },
        ctCounties: async() => {
            return this.topojsonToGeojson(`${environment.url}/maps/ct-counties.topojson`, 'counties');
        },
        usStates: async() => {
            return this.topojsonToGeojson(`${environment.url}/maps/us-states.json`, 'states');
        },
    };

    async topojsonToGeojson(url, objectName) {
        const httpClient = new HttpClient();
        const response = await httpClient.get(url);
        const data = JSON.parse(response.response);
        let geojson = topojson.feature(data, data.objects[objectName]);
        return geojson;
    }

    topojson = {
        usCounties: (style, labelMinZoom = 8, mouseoverSetStyleCallback = undefined, clickSetStyleCallback = undefined, clickCallback = undefined, includeFips = undefined) => {
            const topoStyle = style ?? {
                strokeColor: '#000',
                strokeOpacity: 1.0,
                strokeWeight: 1.0,
                fillColor: 'transparent',
                fillOpacity: 'transparent',
            };
            return {
                id: 'us-counties',
                url: `${environment.url}/maps/us-counties.json`,
                objectName: 'counties',
                includeIds: includeFips,
                clearOnReload: true,
                style: topoStyle,
                label: {
                    cssClass: 'lpfn-county-label',
                    minZoom: labelMinZoom,
                    template: (geojsonFeature) => {
                        return geojsonFeature.properties.name;
                    }
                },
                mouseover: {
                    setStyle: (feature) => {
                        if (!mouseoverSetStyleCallback) return null;
                        return mouseoverSetStyleCallback(feature);
                    }
                },
                click: {
                    setStyle: (feature) => {
                        if (!clickSetStyleCallback) return null;
                        return clickSetStyleCallback(feature);
                    },
                    callback: (feature) => {
                        if (!clickCallback) return null;
                        return clickCallback(feature);
                    }
                }
            };
        },
        usStates: (style, labelMinZoom = 8, mouseoverSetStyleCallback = undefined, clickSetStyleCallback = undefined, clickCallback = undefined) => {
            const topoStyle = style ?? {
                strokeColor: '#000',
                strokeOpacity: 1.0,
                strokeWeight: 1.0,
                fillColor: 'transparent',
                fillOpacity: 'transparent',
            };
            return {
                id: 'us-states',
                url: `${environment.url}/maps/us-states.json`,
                objectName: 'states',
                style: topoStyle,
                label: {
                    cssClass: 'lpfn-state-label',
                    minZoom: labelMinZoom,
                    template: (geojsonFeature) => {
                        return geojsonFeature.properties.name;
                    }
                },
                mouseover: {
                    setStyle: (feature) => {
                        if (!mouseoverSetStyleCallback) return null;
                        return mouseoverSetStyleCallback(feature);
                    }
                },
                click: {
                    setStyle: (feature) => {
                        if (!clickSetStyleCallback) return null;
                        return clickSetStyleCallback(feature);
                    },
                    callback: (feature) => {
                        if (!clickCallback) return null;
                        return clickCallback(feature);
                    }
                }
            };
        }
    };
}